@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 12px;
  padding-bottom: 6px;
  border: none;

  @media (--viewportMedium) {
    padding: 3px 0 29px 12px;
    padding: 4px 0 4px 12px;
  }
}

.list {
  margin: 0;
  padding-left: 0px;
}

.item {
  padding: 2px 0;
  display: flex;
  min-width: 200px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.colorDiv{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding-left: 4px;
}

.item:hover{
  background-color: rgb(247, 246, 246);
}

.count{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  font-size: 10px;
  width: 30px;
  height: 20px;
  border-style: solid;
  border-color: gray;
  border-width: thin;
  color: black;
}
